import React, {useCallback, useEffect, useRef, useState} from 'react';
import AudioControl from './AudioControl';

import '../stylesheets/AvatarVideo.scss';
import {useAddHistory} from "../../utils/hooks/historyHooks";
import {getLLMResponse} from "../../utils/fetch/llmFetching";
import { getAudioResponse } from '../../utils/fetch/speechFetching';
import streamingClient from "../../utils/streaming-client-api";
import {emptyTranscribeResponse, replaceListSSML} from "../../utils/constants";
import { replaceWordsSSML } from "../../utils/helpers";
import { getLocal, setLocal } from '../../utils/dataFetching';

// 'mod' for module; module is a protected word in js files
const AvatarVideo = ({sessionId, modId, mod, audioRef}) => { 
  const [audioState, setAudioState] = useState('ready');
  const [streamState, setStreamState] = useState('connecting');
  const [streamInfo, setStreamInfo] = useState('Connecting to avatar... Please wait');
  const [isTalking, setIsTalking] = useState(false);
  const [audioOnly, setAudioOnly] = useState(mod.avatarAudioOnly ? true : false);
  const [imageOnly,] = useState(mod.avatarImageOnly ? true : false);
  const [aiTranscript, setAITranscript] = useState([]);
  const [startPrompt,] = useState(mod?.startPrompt || null);

  const idleRef = useRef(null);
  const talkRef = useRef(null);
  const {mutateAsync:newHistoryObj} = useAddHistory();

  // Set active session id
  setLocal('active_session_id', sessionId);

  const handlePlayAudio = useCallback(async (text) => {
    try {
      if (audioRef.current) {
        const response = await getAudioResponse(text, sessionId);

        audioRef.current.src = response.data.filepath;
        audioRef.current.load();

        audioRef.current.oncanplay = () => {

          audioRef.current.play().catch(e => {
            alert('Error on play');
            console.log(e);
          });

          setIsTalking(true);
          setAudioOnly(true);
          setAudioState('playing');
          setAITranscript(prev => [...prev, text]);
        }

        audioRef.current.onended = () => {

          setIsTalking(false);
          setAudioOnly(false);
          setAudioState('listening');
        };
        
        await newHistoryObj({sender: 'AI', text: text});
        console.log('played audioRef');
      }
    } catch (e) {
      console.log("AvatarVideo :: handlePlayAudio failure :: ");
      console.log(e.message);
    }
  }, [audioRef, newHistoryObj, sessionId]);

  const handlePlayVideo = useCallback(async (text) => {
    if (talkRef.current && streamState !== "error") {
      // talkRef.current.src = '/talk.mp4';
      // talkRef.current.play();
      try {

        await streamingClient.playStreamText(replaceWordsSSML(text, replaceListSSML));
        await newHistoryObj({sender: 'AI', text: text});
        setAITranscript(prev => [...prev, text]);
        console.log('played talkRef');
      } catch (e) {
        console.log("D-ID :: streamingClient.playStreamText failure :: Audio Fallback Init");
        console.log(e.message);
        try {
          await handlePlayAudio(text);
        } catch (e) {
          console.log(e.message);
        }
      }
    } else {
      console.log("talkref is not current?");
      try {
        await handlePlayAudio(text);
      } catch (e) {
        console.log(e.message);
      }
    }
  }, [talkRef, newHistoryObj, handlePlayAudio, streamState]);

  const handleAudioStateChange = useCallback(async (state, transRes) => {

    if (state === 'ready') {
      if (transRes && transRes.transcription) {
        await newHistoryObj({sender: 'Human', text: transRes.transcription});
        
        const llmRes = await getLLMResponse(sessionId, modId, transRes.transcription, "", "", "", "", "", true, {type:"count",value:50});

        if (llmRes.data?.content) {
          let c = llmRes.data.content;

          // .env var will override everything
          audioOnly ? await handlePlayAudio(c) : await handlePlayVideo(c);
        } 
      } else {
        await handlePlayVideo(emptyTranscribeResponse);
      }
    } else {
      console.log(state);
      setIsTalking(false);
      if (audioOnly) {
        // Do nothing with video, 
      } else {
        talkRef.current?.pause();
      }

      setAudioState(state);
    }

  }, [newHistoryObj, handlePlayVideo, handlePlayAudio, sessionId, modId, audioOnly]);

  const handleVideoEnded = useCallback (() => {
    idleRef.current.play();
    setIsTalking(false);
    setAudioState('listening');
  },[idleRef]);

  useEffect(() => {
    if (startPrompt) {
      handleAudioStateChange('ready', {transcription: startPrompt});
    }
  }, []);

  useEffect(() => {
    // Define the function that will be called when the event is triggered
    const handleStreamingClientAPI = (evt) => {
      console.log('handleStreamingClientAPI: ',evt);
      switch (evt.detail?.message) {
        case 'idle':
          return handleVideoEnded();
        case 'playing':
          setAudioState('ready');

          if (audioOnly) {
            console.log("AvatarVideo :: handleStreamingClientAPI :: audio only = true");
          } else {
            // pause idle to show video
            idleRef.current.pause();
            console.log("AvatarVideo :: handleStreamingClientAPI :: pausing idleRef (idle video)");
          } 

          return setIsTalking(true);
        default:
          setStreamState(evt.detail.message);
          setStreamInfo(evt.detail.info || '');
          console.log(evt.detail.message);
      }
    };

    // Add event listener when component mounts
    window.addEventListener('streaming-client-api', handleStreamingClientAPI);
    window.dispatchEvent(new CustomEvent('streaming-client-avatar',{detail:{message:'onload'}}));

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener('streaming-client-api', handleStreamingClientAPI);
    };
  }, [handleVideoEnded, idleRef, audioOnly]);

  return (
    <div className={audioState + " avatar-container flex-grow-1 " + (isTalking && !audioOnly ? "talking " : "") + streamState}>
      {!imageOnly ? 
        <>
          <video id="idle-video" className="idle-video" ref={idleRef} autoPlay loop muted playsInline>
            <source src={process.env.REACT_APP_ASSETS_URL + '/_avatars/' + mod?.avatar + '/idle.mp4'} type="video/mp4"/>
          Your browser does not support the video tag.
          </video>
          <video id="talk-video" className="talk-video" ref={talkRef} autoPlay playsInline onEnded={handleVideoEnded}>
            <source src={process.env.REACT_APP_ASSETS_URL + '/_avatars/' + mod?.avatar + '/idle.mp4'} type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </> 
        : 
        <>
          <img src={getLocal('avatar_url')} alt="Avatar" />
        </>
      }

      <AudioControl isTalking={isTalking} audioState={streamState === 'connecting' ? 'processing' : audioState} onStateChange={handleAudioStateChange} module={mod} transcript={aiTranscript} />
      {streamState !== 'connected' && streamInfo && <div className="stream-info p-2">{streamInfo}</div>}
    </div>
  );
};

export default AvatarVideo;

